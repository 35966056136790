<template>
	<div class="box">
		<div class="row">
			<div class="col-12">
				<router-link v-if="croissances.length > 0" class="btn btn-secondary" :to="{ name: 'HorseCroissanceGraph', params: {horse_id: horse_id}}">{{ $t('croissance.comparer_population') }} <font-awesome-icon :icon="['fal', 'chart-pie']" /></router-link>
        		<router-link class="btn btn-primary ml-2" :to="{ name: 'AddHorseCroissance', params: {horse_id: horse_id}}">{{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus']" /></router-link>
			</div>
			<div v-if="ready" class="col-12 mt-4">
				<div class="d-none d-md-block table-responsive">
					<table class="table table-hover table-sm mb-0">
						<thead>
							<tr>
								<th>{{ $t('croissance.date_mesure') }}</th>
								<th>{{ $t('croissance.horse_perimetre') }}</th>
								<th>{{ $t('croissance.horse_garrot') }}</th>
								<th>{{ $t('croissance.horse_masse') }}</th>
								<th>{{ $t('croissance.horse_nec') }}</th>
								<th>{{ $t('croissance.modification') }}</th>
							</tr>
						</thead>

						<tbody>
						<tr v-for="croissance in croissances" :key="croissance.croissance_id">
							<td>
								{{ croissance.date }}
							</td>
							<td>{{ croissance.croissance_perimetre }}</td>
							<td>{{ croissance.croissance_garrot }}</td>
							<td >{{ croissance.croissance_poids }}</td>
							<td >{{ croissance.croissance_nec }}</td>
							<td ><router-link class="btn btn-primary" :to="{ name: 'AddHorseCroissance', params: {horse_id: horse_id, croissance_id: croissance.croissance_id }}">{{ $t('global.modifier') }} <font-awesome-icon :icon="['fal', 'angle-right']" /></router-link></td>
						</tr>
						</tbody>
					</table>
				</div>
				<div class="d-md-none">
					<div v-for="croissance in croissances" :key="croissance.croissance_id" class="border-bottom pb-2">
						<div>{{ $t('croissance.date_mesure') }} : {{ croissance.date }}</div>
						<div>{{ $t('croissance.horse_perimetre') }} : {{ croissance.croissance_perimetre }}</div>
						<div>{{ $t('croissance.horse_garrot') }} : {{ croissance.croissance_garrot }}</div>
						<div>{{ $t('croissance.horse_masse') }} : {{ croissance.croissance_poids }}</div>
						<div>{{ $t('croissance.horse_nec') }} : {{ croissance.croissance_nec }}</div>
						<div><router-link :to="{ name: 'AddHorseCroissance', params: {horse_id: horse_id, croissance_id: croissance.croissance_id }}">{{ $t('global.modifier') }} <font-awesome-icon :icon="['fal', 'angle-right']" /></router-link></div>
					</div>
				</div>
			</div>
			<LoadingSpinner v-else />
		</div>
	</div>
</template>

<script type="text/javascript">
	import Croissance from "@/mixins/Croissance.js"
	import Tools from "@/mixins/Tools.js"

	export default {
		name: 'HorseCroissance',
		props: ['horse_id'],
		mixins: [Croissance, Tools],
		data () {
			return {
				croissances: [],
				ready: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.ready = false

				let id = this.horse_id
				if(id < 0) {
	                await this.$sync.force()
	                id = this.$sync.replaceWithReplicated('horse', this.horse_id)
	            }

				this.croissances = await this.getCroissanceByHorse(id)

				this.croissances.forEach(croissance => {
					croissance.date = this.formatDate(croissance.croissance_date)
				})
				this.ready = true
			}
		},

		watch: {
			'$route.name'() {
				this.croissances = []
				this.init_component()
			}
		},

		components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35') 
		}
	}

</script>